
import Vue from "vue";
import BaseList from "@/components/BaseList.vue";
import { User } from "@/types";
import i18n from "@/plugins/i18n";
import router from "@/router";

const Component = Vue.extend({
  name: "LayoutTopMenu",
  components: {
    "base-list": BaseList,
  },
  computed: {
    menuItems(this: any) {
      if (this.$store.state.authentication.status.loggedIn) {
        const items = [
          {
            title: i18n.t("OCCURRENCE.TITLE"),
            route: "/ereignis",
            action: () => this.navigate("/ereignis"),
            icon: "report",
          },
          {
            title: i18n.t("MENU.REPORTS"),
            route: "/report",
            action: () => this.navigate("/report"),
            icon: "description",
          },
          {
            title: i18n.t("MENU.DEBUG"),
            route: "/debug",
            action: () => this.navigate("/debug"),
            icon: "bug_report",
            role: "Administrator",
          },
          {
            title: i18n.t("MENU.USERMANAGEMENT"),
            route: "/users",
            action: () => this.navigate("/users"),
            icon: "people",
            role: "Administrator",
          },
          {
            title: i18n.t("GENERAL.CHANGEPASSWORD"),
            action: () => this.$changePassword(),
            icon: "vpn_key",
          },
          {
            title: i18n.t("DATENSCHUTZBESTIMMUNGEN"),
            action: () => this.openDatenschutzbestimmungen(),
            icon: "lock",
          },
          {
            title: i18n.t("MENU.LOGOUT"),
            route: "/signing",
            action: () => this.navigate("/signin"),
            icon: "exit_to_app",
          },
        ];
        return items.filter((item) => {
          return this.hasRole(item.role);
        });
      } else {
        return [];
      }
    },
    me(): User {
      const data = this.$store.getters["authentication/user"];
      return data;
    },
    language(): string {
      return this.$store.state.locale;
    },
  },
  methods: {
    hasRole(role: string): boolean {
      return !role || (this.me.roles || []).indexOf(role) > -1;
    },
    switchLanguage(): void {
      const { dispatch } = this.$store;
      if (this.language === "de") {
        dispatch("setLang", "en");
      } else {
        dispatch("setLang", "de");
      }
    },
    navigate(action: string) {
      this.transitioning = true;
      this.sidebar = false;
      this.newRoute = action;
    },
    openDatenschutzbestimmungen() {
      window.open("https://www.ics-fs.at/datenschutz/", "_blank");
    },
    drawerTransEnded() {
      this.transitioning = false;
      if (!this.sidebar && this.newRoute !== "") {
        router.push(this.newRoute);
        this.newRoute = "";
      }
    },
  },
  data() {
    const today = new Date();
    const year = today.getFullYear();

    return {
      year,
      sidebar: false,
      transitioning: false,
      newRoute: "",
    };
  },
});
export default Component;
